import { version } from './version';
import { ApiEnvironment } from '@clients/api';
import {
  VersionEnvironment,
  SentryEnvironment,
  FirebaseEnvironment,
} from '@clients/helper';

export const environment: VersionEnvironment &
  ApiEnvironment &
  SentryEnvironment &
  FirebaseEnvironment & { production: boolean } = {
  production: true,
  version,
  api: 'https://api.app.get-the-guest.com',
  sentry: {
    dns: 'https://826ecc3191be4f9a852c3c551684d0f1@sentry.fortysix.world/13',
    environment: 'prod',
    enabled: true,
  },
  firebase: {
    apiKey: 'AIzaSyDXoLDNNDEql1oQ6Ov5az4ovHR1IWJOAcs',
    authDomain: 'get-the-guest.firebaseapp.com',
    databaseURL: 'https://get-the-guest.firebaseio.com',
    projectId: 'get-the-guest',
    storageBucket: 'get-the-guest.appspot.com',
    messagingSenderId: '749272438059',
    appId: '1:749272438059:web:68b7f9d2106df81512a16e',
    measurementId: 'G-7S4YJGQRVT',
  },
};
